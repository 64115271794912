import * as React from 'react';

// CSS
import './noExist.css'

// Iconos
import noExist from '../../assets/noExiste.png'

// React router
import { useNavigate } from 'react-router-dom';

// boostrap
import Button from 'react-bootstrap/Button';

function NoExist() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();

    const onSubmitExit = () => {
        navigate('/');
        window.location.reload();
    };

    return (
        <div className="noExist">
          <section className="section4" style={{backgroundColor: "#3D9ADE"}}>
            <div className="container">
              <div className="card shadow-2-strong" style={{borderRadius: "3rem"}}>
                <div className="card-body p-5 text-center">
                    <img className="img-fluid" src={noExist} alt="Club Puntos Excelencia" style={{height: "200px", width: "180px"}}/>
                    <h3 className="mb-2 mt-3">Lo sentimos al parecer no te encuentras inscrito en el club de la excelencia</h3>
                
                    <div className=''>
                        <form onSubmit={ev => {
                            onSubmitExit();
                            }}>
                  
                        <Button style={{
                        backgroundColor: "#F2ED39",
                        borderRadius: "20px",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "20px"
                      }} 
                      variant=""
                      type="submit"
                      >Pagina Principal</Button>
                        </form>
                    </div>
                </div>
              </div>
            </div>
          </section>   
      </div>
    );
  }
  
  export default NoExist;