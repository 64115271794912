import React, { useState, useEffect } from 'react';

// Iconos
import Logo from '../../assets/logo.png'


// reCaptcha
import ReCAPTCHA from "react-google-recaptcha";

// Cokkies
import Cookies from 'universal-cookie'

// CSS
import './mainForm.css'

// boostrap
import Button from 'react-bootstrap/Button';

import FormResponse from '../formResponse/formResponse';

function mainForm() {

  // ESTADOS ------------------------------------------------
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [cedula, setCedula] = useState(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ccValida, setCcValida] = useState(null); 
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [captchaValido, setCaptchaValido] = useState(null); 
  const captchaRef = React.createRef()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [response, setResponse] = useState(false); 
  // ESTADOS ------------------------------------------------


  // OBTENER TOKEN Y GUARDAR EN COOKIE ----------------------
  const getApiToken = async e =>{
    try {
      let user = {
        user:'lduque',
        password:'sistemas2020+', 
        app: 'VISITAS',
      }

      let config = {
        method: 'POST',
        headers : {
          'Accept': 'application/json',
          'content-type': 'application/json'
        },
        body: JSON.stringify(user)
      }

      let res = await fetch('https://api.bateriaswillard.com/login', config)
      let json = await res.json();


      const cookie = new Cookies();
      cookie.set("token_auth",json.data.access_token,{path: '/'})
    
    } catch (error) {
    }
}
// OBTENER TOKEN Y GUARDAR EN COOKIE ----------------------


// ESCAPADO DE STRING -------------------------------------
  function escape(str) {
    return str.replace(/&/g, "")
          .replace(/</g, "")
          .replace(/>/g, "")
          .replace(/"/g, "")
          .replace(/'/g, "")
          .replace(/-/g, "");            
 }
// ESCAPADO DE STRING -------------------------------------

// FUNCION PARA CAPTCHA VALIDO ----------------------------
function onChange()
{
  const captchaValue = captchaRef.current.getValue();
  captchaValue ? setCaptchaValido(true) : setCaptchaValido(false);
}
// FUNCION PARA CAPTCHA VALIDO ----------------------------


// FUNCION PARA CC VALIDA ---------------------------------
  function onChangeCc()
  {
    const cedula = document.getElementById('cedula').value;

    if (cedula.length <= 10 || cedula.length < 0){
      setCcValida(true);
    }else{
      setCcValida(false);
    }
  }
  // FUNCION PARA CC VALIDA ---------------------------------


  // FUNCION DE EJECUCION PARA BUSCAR LOS DATOS Y VALIDAR ---
  const onSubmit = async (cc) => {
   
    const ccVal = (`${cc}`.length);
    const captchaValue = captchaRef.current.getValue();
    captchaValue ? setCaptchaValido(true) : setCaptchaValido(false);

  
    cc === '' || ccVal >= 10? setCcValida(false) : setCcValida(true);


    if (captchaValido && ccValida){
      setCaptchaValido(false);
      setCcValida(false);
      setCedula(cc)
      setResponse(true)
    }
  };
  // FUNCION DE EJECUCION PARA BUSCAR LOS DATOS Y VALIDAR ---


  // FUNCION DE ESTADO PARA OBTENER EL TOKEN ------------
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getApiToken();
  }, []);
  // FUNCION DE ESTADO PARA OBTENER EL TOKEN ------------

  return (
    <>
    {response === false &&
    <div className="mainForm">
      <form onSubmit={ev => {
        ev.preventDefault();
        const cc = ev.target.cedula.value;
        if (cc !== ''){
        const ccNew = escape(cc.toString());
        const cedula = parseInt(ccNew);
        isNaN(cedula) ? window.location.reload() : onSubmit(cedula);
        }else{
          setCcValida(false);
        }
        }}>
        <section className="section" style={{backgroundColor: "#3D9ADE"}}>
          <div className="container">
            <div className="card shadow-2-strong" style={{borderRadius: "1rem"}}>
              <div className="card-body p-5 text-center">

                <img className="img-fluid" src={Logo} alt="Club Puntos Excelencia" style={{height: "210px", width: "180px"}}/>
                <h3 className="mb-2 mt-3">Consultar Puntos de la excelencia</h3>

                <div className="form-outline mb-4">
                  <input type="number" name='cedula'  id="cedula" className="form-control form-control-lg" onChange={onChangeCc}/>
                  <label className="form-label" htmlFor="typeEmailX-2">Cedula</label>
                  {ccValida === false &&        
                <div className="form-outline m-2">
                  <h6 style={{color: "red"}}>Cedula Invalida</h6>
                </div>
                 } 
                </div>

                <div className="recaptcha mb-4">
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey="6LecDMYhAAAAAI21wcRq017auW9ZS75T2UAA5e2v"
                    onChange={onChange}
                  />
                </div>

                {captchaValido === false &&        
                <div className="form-outline m-2">
                  <h6 style={{color: "red"}}>Verifique el Captcha</h6>
                </div>
                 }      
              
                <Button style={{
                  backgroundColor: "#3D9ADE",
                  borderRadius: "20px",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "20px"
                }} 
                variant=""
                type="submit"
                >Consultar</Button>
                
              </div>
            </div>
          </div>
        </section>   
      </form>
    </div>
    }

    {response === true &&
      <FormResponse cc={cedula}/>
    }

  </>
  );
}

export default mainForm;
