import * as React from 'react';

// CSS
import './noFound.css'

// Iconos
import image from '../../assets/404.png'

// React router
import { useNavigate } from 'react-router-dom';

// boostrap
import Button from 'react-bootstrap/Button';

function NoFound() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();

    const onSubmit = () => {
      navigate('/');
      window.location.reload();
  };

    return (
        <div className="NoFound">
          <section className="section4" style={{backgroundColor: "#3D9ADE"}}>
            <div className="container">
              <div className="card shadow-2-strong" style={{borderRadius: "3rem"}}>
                <div className="card-body p-5 text-center">
                    <img className="img-fluid" src={image} alt="Club Puntos Excelencia" style={{height: "230px", width: "230px"}}/>
                    <h3 className="mb-2 mt-3">Lo sentimos esta pagina no existe</h3>
                
                    <div className=''>
                        <form onSubmit={ev => {
                            onSubmit();
                            }}>
                    

                          <Button style={{
                            backgroundColor: "#E3A73D",
                            borderRadius: "20px",
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "20px"
                          }} 
                          variant=""
                          type="submit"
                          >Pagina Principal</Button>
                        </form>
                    </div>
                </div>
              </div>
            </div>
          </section>   
      </div>
    );
  }
  
  export default NoFound;