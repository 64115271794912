import React, {useState , useEffect}  from 'react';

// React Router
import { useNavigate } from 'react-router-dom';

// Iconos
import logo from '../../assets/logoW.png'
import puntos from '../../assets/puntos.png'
import redimibles from '../../assets/redimibles.png'
import total from '../../assets/total.png'
import valor from '../../assets/valor.png'

// Cokkies
import Cookies from 'universal-cookie'

// CSS
import './formResponse.css'

// boostrap
import Button from 'react-bootstrap/Button';

//Spinner
import ClipLoader from "react-spinners/ClipLoader";


function formResponse(props) {
      const cc = props.cc;
      const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
      })
      

     // eslint-disable-next-line react-hooks/rules-of-hooks
     let [data, setData] = useState(null);
     const userData = [];
  
    // eslint-disable-next-line react-hooks/rules-of-hooks
    let [dataOk, setDataOk] = useState(null);

     // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();

     // eslint-disable-next-line react-hooks/rules-of-hooks, react-hooks/exhaustive-deps
     useEffect(() => {
        
      getDataUser(cc);
      
      }, []);

      

      function noExist(){
        navigate('/noExist');
      }

      function noFound(){
        navigate('/noFound');
      }
    
      const onSubmit = () => {
        setDataOk(false)
        setData(null)
        navigate('/');
        window.location.reload();
    };


      
        // OBTENER DATOS DEL USUARIO
        const getDataUser = async (cc) =>{
          try {
           
            const cookie = new Cookies();
            const token_auth = cookie.get('token_auth');
        
            let config = {
              method: 'GET',
              headers : {
                'Accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': token_auth
              },
            }
        
            const res = await fetch(`https://api.bateriaswillard.com/sirh/employees/clubPoints/${cc}`, config)

            const json = await res.json();
      

             if (`${cc}`.length > 10){
                  setDataOk(false);
                  noFound();
                }else{
             
                    if(json.data.length === 0){
                      setDataOk(false);
                      noExist();
                    }else{
                      setDataOk(true);
                      setData(json);
                    }
              
                }
          
          } catch (error) {
          }
        }
        // OBTENER DATOS DEL USUARIO

        if(data !== null ){
        
          userData.push(data.data[0].NOM_EMP);
          userData.push(data.data[0].TOTAL_PUNTOS);
          userData.push(data.data[0].PUNTOS_REDIMIBLES);
          userData.push(data.data[0].VALOR_PUNTO);
          userData.push(formatterPeso.format(data.data[0].TOTAL_DINERO));
          }
    
  return (
    <>
    {dataOk !== true &&
    <div className="align-items-center justify-content-center d-flex" style={{backgroundColor: "#3D9ADE", width: "100%", height: "100vh"}}>
      <ClipLoader color={'black'} loading={true} cssOverride={{}} size={150} />
    </div>
       
    }
    { dataOk === true &&
    <div className="formResponse">
    <section className="section2" style={{backgroundColor: "#3D9ADE"}}>
      <div className="container2">
        <div className=" p-5 bg-body shadow-sm m-2" style={{borderRadius: "2rem"}}>
          <h6 className="border-bottom pb-2 mb-0">Bienvenido {userData[0]} </h6>
            
            <div className="d-flex text-muted pt-3">
              <img className='icon bd-placeholder-img flex-shrink-0 me-2 rounded' src={puntos} alt="Total de puntos"/>
              <p className="p-1 mb-0 small lh-sm border-bottom">
              <span className="d-block text-gray-dark">Total de puntos: <strong>{userData[1]}</strong></span>
              </p>
            </div>

            <div className="d-flex text-muted pt-3">
              <img className='icon bd-placeholder-img flex-shrink-0 me-2 rounded' src={redimibles} alt="Puntos redimibles"/>
              <p className="p-1 mb-0 small lh-sm border-bottom">
              <span className="d-block text-gray-dark">Puntos redimibles: <strong>{userData[2]}</strong></span>
              </p>
            </div>

            <div className="d-flex text-muted pt-3">
              <img className='icon bd-placeholder-img flex-shrink-0 me-2 rounded' src={valor} alt="Valor de cada punto"/>
              <p className="p-1 mb-0 small lh-sm border-bottom">
              <span className="d-block text-gray-dark">Valor de cada punto: <strong>{userData[3]}</strong></span>
              </p>
            </div>

            <div className="d-flex text-muted pt-3">
              <img className='icon bd-placeholder-img flex-shrink-0 me-2 rounded' src={total} alt="Total en dinero"/>
              <p className="p-1 mb-0 small lh-sm border-bottom">
              <span className="d-block text-gray-dark">Total en dinero: <strong>{userData[4]}</strong></span>
              </p>
            </div>
   

              <small className="d-block text-end mt-3">
                <span className='me-2'><strong>Baterias Willard</strong></span>
                <img className='iconW bd-placeholder-img flex-shrink-0 me-2 rounded' src={logo} alt="Total de puntos"/>
              </small>
        </div>

          <div className='section3'>
            <form onSubmit={ev => {
                onSubmit();}}>
              <Button style={{
                  backgroundColor: "#55F239",
                  borderRadius: "20px",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "20px"
                }} 
                variant=""
                type="submit"
                >Realizar Otra Consulta</Button>
            </form>
          </div>

      </div>
    </section>   
</div>
}      
</>
  );
}

export default formResponse;
