import * as React from 'react';

import { Route, Routes,BrowserRouter } from "react-router-dom";

import MainForm from '../components/mainForm/mainForm'
import FormResponse from '../components/formResponse/formResponse'
import NoExist from '../components/noExist/noExist'
import NoFound from '../components/noFound/noFound';


function App() {

  

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NoFound />} />
        <Route path="noFound/" element={<NoFound />} />
        <Route path="/" element={<MainForm />}/>
        <Route path="noExist/" element={<NoExist />} />
      </Routes>
     </BrowserRouter>
  );
}

export default App;
